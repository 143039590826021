body {
  padding: 0px;
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 10pt;
  padding-bottom: 40px;
}
* {
  box-sizing: border-box;
}

.content {
  padding: 0px 10px;
}

/************************************************/
/*Basic Split Cols*/
/************************************************/
.container-row {
  margin: 10px 0px 0px 0px;
}
.container-row:after,
.container-row:before {
  display: table;
  content: ' ';
}
.container-row:after {
  clear: both;
}
.claim-state-filters {
  padding: 0 5px;
}
.claim-state-filters button {
  margin: 8px;
}
.claim-state-filters button:first-child {
  margin-left: 0;
}

.claim-state-filters li:hover {
  background-color: #f4f4f4;
}
.claim-state-filters li.active {
  background-color: #e458dd;
}

.MuiSelect-selectMenu .list-only {
  display: none;
}
.highlight:hover {
  cursor: pointer;
}
