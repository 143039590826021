ul.pageButtons {
  background: rgb(77, 93, 145);
  margin: 0px;
  padding: 0px;
  border: 1px solid rgb(29, 39, 71);
  border-radius: 5px;
  display: block;
  float: left;
  height: 32px;
  list-style-type: none;
}

ul.pageButtons li {
  list-style: none;
  padding: 8px 0px 0px 0px;
  display: block;
  float: left;
  width: 30px;
  height: 30px;
  font-weight: bold;
  border-right: 1px solid rgb(29, 39, 71);
  text-align: center;
  font-size: 8pt;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
}
ul.pageButtons li.active {
  background: rgb(62, 169, 211);
}
ul.pageButtons li:first-child {
  border-radius: 5px 0px 0px 5px;
}
ul.pageButtons li:last-child {
  border-right: none;
  border-radius: 0px 5px 5px 0px;
}
ul.pageButtons li:hover {
  background-color: rgb(105, 126, 196);
}

.paging-display {
  color: rgb(62, 169, 211);
  display: block;
  float: left;
  padding: 10px 0px 0px 5px;
}
