.gender-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1.33333333em;
  margin-right: 2px;
}

.gender-icon.male {
  background-image: url('../images/gender/male.png');
}
.gender-icon.female {
  background-image: url('../images/gender/female.png');
}
